import React from 'react'

import Lottie from 'react-lottie'

export const useViewport = (id) => {
	const [isInViewport, setIsInViewport] = React.useState(false)

	React.useEffect(
		() => {
			let elementWatcher
			import("scrollmonitor").then(
				({ default: scrollMonitor }) => {
					const container = document.getElementById(id)
					if (!container) return
					elementWatcher = scrollMonitor.create(container);

					elementWatcher.stateChange(function(state) {
						setIsInViewport(elementWatcher)
					})
				}
			)

			return () => elementWatcher && elementWatcher.destroy()
		},
		[id]
	)

	return isInViewport
}

export default function ({ id, className, animationData, height, width }) {
	const {isInViewport} = useViewport(id)

	return (
		<div
			id={id}
			className={className}>
			<Lottie
				height={height}
				width={width}
				isPaused={!isInViewport}
				options={
					{
						loop: true,
						autoplay: true,
						animationData: animationData,
						rendererSettings: {
							preserveAspectRatio: 'xMidYMid slice'
						}
					}
				}
			/>
		</div>
	)
}

// export default Watch(({ isInViewport, id, className, animationData, height, width }) => {	
// 	return (
// 		<div 
// 			id = { id } 
// 			className = { className }>
// 			<Lottie
// 				height = { height }
// 				width = { width }
// 				isPaused = { !isInViewport }
// 				options = {{
// 					loop: true,
// 					autoplay: true, 
// 					animationData: animationData,
// 					rendererSettings: {
// 						preserveAspectRatio: 'xMidYMid slice'
// 					}}
// 				} />
// 		</div>
// 	)
// });
